body { background-color: var(--clr-2709); }
body { color: var(--clr-2710); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-2712);
font-family: 'Oswald';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 30px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-2710);
font-family: 'Oswald';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 26px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: var(--clr-2710);
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 24px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: var(--clr-2710);
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: var(--clr-2710);
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
h6 {
color: var(--clr-2710);
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

}
.button {
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
ol,ul {
color: var(--clr-2710);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
summary {
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-2711);}
/* Primary:3 */
.MES3 {
background-color: var(--clr-2712);
color: #ffffff;
 }
/* Primary:4 */
.MES4 {
background-color: var(--clr-2712);
color: #ffffff;
 }
.MES4 {
background-color: var(--clr-2712);
color: #ffffff;
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: #ffffff;
 }
 }
cite.MEC4{
color: #ffffff;
}
/* Secondary:5 */
.MES5 {
background-color: var(--clr-2711);
color: #ffffff;
 }
/* Secondary:6 */
.MES6 {
background-color: var(--clr-2711);
color: #ffffff;
 }
.MES6 {
background-color: var(--clr-2711);
color: #ffffff;
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: #ffffff;
 }
 }
.MEC6 li {color: #ffffff;}
cite.MEC6{
color: #ffffff;
}
/* Dark:7 */
.MES7 {
background-color: var(--clr-2710);
color: #ffffff;
 }
/* Dark:8 */
.MES8 {
background-color: var(--clr-2713);
color: var(--clr-2709);
 }
.MES8 {
background-color: var(--clr-2713);
color: var(--clr-2709);
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: var(--clr-2709);
 }
 }
a.MEC8 { color: var(--clr-2709);
&:hover { color: var(--clr-2711);}
 }
cite.MEC8{
color: var(--clr-2709);
}
/* Light:9 */
.MES9 {
background-color: var(--clr-2709);
 }
/* Light:10 */
.MES10 {
background-color: var(--clr-2709);
 }
.MES10 {
background-color: var(--clr-2709);
 }
/* Shade 1:11 */
.MES11 {
background-color: var(--clr-2714);
 }
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-2714);
 }
.MES12 {
background-color: var(--clr-2714);
 }
/* Menu:13 */
.MES13 {
color: #ffffff;
font-size: 16px;
 }
.MES13 {
color: #ffffff;
font-size: 16px;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
a.MEC13 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC13{
color: #ffffff;
font-size: 16px;
}
/* home block blue:14 */
.MES14 {
background-color: var(--clr-2711);
&:hover, &.hover { background-color: var(--clr-2712);}
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://toolcraft.net.au/img/1075/64');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:150px;
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 200px;};
 }
.MES14 {
background-color: var(--clr-2711);
&:hover, &.hover { background-color: var(--clr-2712);}
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://toolcraft.net.au/img/1075/64');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:150px;
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 200px;};
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: #ffffff;
 }
 }
.MEC14 li {color: #ffffff;}
cite.MEC14{
color: #ffffff;
}
/* banner:15 */
.MES15 {
background-color: #ffffff;
padding: 7px 10px;

 }
.MES15 {
background-color: #ffffff;
padding: 7px 10px;

 }
/* Hollaw:16 */
.MES16 {
color: #ffffff;
&:hover { color: #ffffff;}
border-width: 2px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #ffffff; }
 }
/* test:17 */
.MES17 {
 }
/* Contents:18 */
.MES18 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
color: var(--clr-2710);
font-size: 16px;
padding: 20px 0;

@media #{$medium-up} {
padding: 40px 15px;

}
 }
.MES18 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
color: var(--clr-2710);
font-size: 16px;
padding: 20px 0;

@media #{$medium-up} {
padding: 40px 15px;

}
 }
a.MEC18 { color: var(--clr-2712);
&:hover { color: var(--clr-2710);}
 }
cite.MEC18{
color: var(--clr-2710);
font-size: 16px;
}
/* Slider text 2:19 */
.MES19 {
 }
.MES19 {
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: #ffffff;
 }
h1.MEC19 { @media #{$medium-up} { font-size: 44px; }; }
h1.MEC19 { @media #{$large-up} { font-size: 48px; }; }
h2.MEC19 { @media #{$medium-up} { font-size: 38.5px; }; }
h2.MEC19 { @media #{$large-up} { font-size: 42px; }; }
h3.MEC19 { @media #{$medium-up} { font-size: 33px; }; }
h3.MEC19 { @media #{$large-up} { font-size: 36px; }; }
h4.MEC19 { @media #{$medium-up} { font-size: 27.5px; }; }
h4.MEC19 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC19 { @media #{$medium-up} { font-size: 22px; }; }
h5.MEC19 { @media #{$large-up} { font-size: 24px; }; }
h6.MEC19 { @media #{$medium-up} { font-size: 17.6px; }; }
h6.MEC19 { @media #{$large-up} { font-size: 19.2px; }; }
 }
/* Footer Dark:20 */
.MES20 {
background-color: var(--clr-2709);
color: var(--clr-2713);
font-size: 11.2px;
padding: 10px;

 }
.MES20 {
background-color: var(--clr-2709);
color: var(--clr-2713);
font-size: 11.2px;
padding: 10px;

h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: var(--clr-2710);
 }
 }
a.MEC20 { color: var(--clr-2709);
&:hover { color: var(--clr-2711);}
 }
cite.MEC20{
color: var(--clr-2713);
font-size: 11.2px;
}
/* News grid:21 */
.MES21 {
color: var(--clr-2710);
border-width: 1px;
border-style: solid;
border-color: var(--clr-2709);
 }
.MES21 {
color: var(--clr-2710);
border-width: 1px;
border-style: solid;
border-color: var(--clr-2709);
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: var(--clr-2713);
 }
 }
cite.MEC21{
color: var(--clr-2710);
}
/* About FAQ:22 */
details.MES22 {
& > summary{background-color: var(--clr-2709);
}
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent #ffffff transparent;
} }
/* Home FAQ:23 */
details.MES23 {
& > summary{background-color: var(--clr-2712);
}
color: #ffffff;
& > article { color: #ffffff;
 }
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent #ffffff transparent;
} }
/* Enquiry Button:24 */
.MES24 {
background-color: var(--clr-2711);
&:hover {background-color: var(--clr-2711);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 19.2px;
border-radius: 3px;
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #ffffff; }
 }
/* Services:27 */
.MES27 {
background-color: var(--clr-2712);
&:hover {background-color: var(--clr-2712);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 3px;
padding: 5px 10px;

border-width: 1px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #ffffff; }
 }
/* Product Item:28 */
.MES28 {
padding: 0 0 15px 0;

border-width: 1px;
border-style: solid;
border-color: var(--clr-2709);
 }
.MES28 {
padding: 0 0 15px 0;

border-width: 1px;
border-style: solid;
border-color: var(--clr-2709);
 }
/* product image:29 */
.MES29 {
min-height:100px;
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 200px;};
 }
.MES29 {
min-height:100px;
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 200px;};
 }
/* Footer Menu:30 */
nav.me-Menu.MES30 {
& .menu-item.MEC30, & .menu-item.MEC30 > div.MEC30{ & > a.MEC30{color: #ffffff;
font-size: 14.4px;
}
 &:hover > a.MEC30{color: var(--clr-2714);
}
 }
&.horizontal > .menu-item.MEC30 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC30 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC30 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC30 .sub-menu { border:1px;
 }
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Call to Action:31 */
.MES31 {
background-color: var(--clr-2712);
color: #ffffff;
font-size: 12.8px;
@media #{$large-up} {
font-size: 16px;
};
padding: 20px;

 }
.MES31 {
background-color: var(--clr-2712);
color: #ffffff;
font-size: 12.8px;
@media #{$large-up} {
font-size: 16px;
};
padding: 20px;

h1.MEC31, h2.MEC31, h3.MEC31, h4.MEC31, h5.MEC31, h6.MEC31 { color: #ffffff;
 }
h1.MEC31 { @media #{$medium-up} { font-size: 22px; }; }
h1.MEC31 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC31 { @media #{$medium-up} { font-size: 19.25px; }; }
h2.MEC31 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC31 { @media #{$medium-up} { font-size: 16.5px; }; }
h3.MEC31 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC31 { @media #{$medium-up} { font-size: 13.75px; }; }
h4.MEC31 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC31 { @media #{$medium-up} { font-size: 11px; }; }
h5.MEC31 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC31 { @media #{$medium-up} { font-size: 8.8px; }; }
h6.MEC31 { @media #{$large-up} { font-size: 16px; }; }
 }
a.MEC31 { color: #ffffff;
&:hover { color: var(--clr-2716);}
 }
cite.MEC31{
color: #ffffff;
font-size: 12.8px;
@media #{$large-up} {
font-size: 16px;
};
}
/* Category block:32 */
.MES32 {
background-color: var(--clr-2711);
&:hover, &.hover { background-color: var(--clr-2712);}
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://toolcraft.net.au/img/1075/64');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 15px;

 }
.MES32 {
background-color: var(--clr-2711);
&:hover, &.hover { background-color: var(--clr-2712);}
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://toolcraft.net.au/img/1075/64');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 15px;

h1.MEC32, h2.MEC32, h3.MEC32, h4.MEC32, h5.MEC32, h6.MEC32 { color: #ffffff;
 }
 }
.MEC32 li {color: #ffffff;}
cite.MEC32{
color: #ffffff;
}
/* block blue:33 */
.MES33 {
background-color: var(--clr-2712);
&:hover, &.hover { background-color: var(--clr-2711);}
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://toolcraft.net.au/img/1075/64');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:100px;
@media #{$medium-up} {
min-height: 100px;};
@media #{$large-up} {
min-height: 100px;};
 }
.MES33 {
background-color: var(--clr-2712);
&:hover, &.hover { background-color: var(--clr-2711);}
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://toolcraft.net.au/img/1075/64');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:100px;
@media #{$medium-up} {
min-height: 100px;};
@media #{$large-up} {
min-height: 100px;};
h1.MEC33, h2.MEC33, h3.MEC33, h4.MEC33, h5.MEC33, h6.MEC33 { color: #ffffff;
 }
 }
.MEC33 li {color: #ffffff;}
cite.MEC33{
color: #ffffff;
}
/* Page Accordion:34 */
details.MES34 {
 }
